$dark: #212121;
$accent: #faa650;
$accentHover: #fdaf60;
$radius: 4px;
$shadow: 0 10px 10px rgba(0, 0, 0, 0.1);

@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
    font-size: 10px;
}

body {
    font-family: 'Roboto', sans-serif;
    font-size: 1.5rem;
}

.app {
    min-height: calc(100vh - 70px);
}

.grid {
    width: calc(100% - 100px);
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    &--full {
        width: 100%;
        max-width: 100%;
        padding-left: 30px;
        padding-right: 30px;
    }
}

// Temporary Pages
.page-error {
    display: flex;
    width: 100%;
    min-height: calc(100vh - 440px);
    justify-content: center;
    align-items: center;
    padding: 150px 0;
    &__content {
        text-align: center;
        h1 {
            font-size: 4.8rem;
            color: $dark;
            line-height: 1.1;
            margin: 0 0 15px 0;
        }
        p {
            font-size: 1.9rem;
            color: $dark;
            line-height: 1.3;
            margin: 0 0 5px 0;
            &:last-child {
                margin: 0;
            }
        }
        .btn {
            margin-top: 40px;
        }
    }
}
.soon {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    width: 100%;
    height: 100vh;
    color: #fff;
    background: $dark;
    &__content {
        text-align: center;
        .logo {
            margin: 0 0 30px 0;
            width: 100%;
            max-width: 170px;
        }
        h1 {
            font-size: 3.6rem;
            line-height: 1.1;
            margin: 0 0 10px 0;
        }
        p {
            line-height: 1.1;
            font-size: 1.9rem;
            margin: 0 0 30px 0;
        }
    }
}

// Header Components Style
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: $dark;
    box-shadow: $shadow;
    z-index: 998;

    &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        height: 70px;
    }
}
.logo {
    display: inline-block;
    width: 100%;
    max-width: 120px;
    img {
        display: block;
        width: 100%;
    }
}
.navigation {
    margin: 0 auto 0 30px;
    ul {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
    }
    &__item {       
        margin: 0 20px 0 0;
        &:last-child {
            margin: 0;
        }
        a {
            color: #fff;            
            text-decoration: none;
            &:hover {
                color: $accent;
            }
        }
        &--drop {
            position: relative;
            .navigation__toggle {
                position: relative;
                &::after {
                    content: '';
                    display: inline-block;
                    width: 8px;
                    height: 6px;
                    background-image: url(/images/arrow-down-white.svg);
                    background-size: cover;
                    margin: 0 0 1px 5px;
                    vertical-align: middle;
                }
            }
            .navigation {
                &__dropdown {
                    display: none;
                    position: absolute;
                    left: -15px;
                    width: 250px;
                    padding: 25px 15px 15px 15px;
                    background: $dark;
                    border-radius: 0 0 $radius $radius;
                    box-shadow: $shadow;
                }
                &__item {
                    display: block;
                    width: 100%;
                    margin: 0 0 15px 0;
                    &:last-child {
                        margin: 0;
                    }
                }
            }
            &:hover {
                .navigation__dropdown { display: block; }
            }
        }
        &--button {
            a {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-flow: row wrap;
                height: 42px;
                padding: 0 25px;
                font-weight: 600;
                color: #fff;
                text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                background: $accent;
                border-radius: $radius;
                &:hover {
                    color: #fff;
                    background: $accentHover;
                }
            }            
        }
    }
    &--vertical {
        margin: 0;
        .navigation__item {
            float: none;
            display: block;
            width: 100%;
            margin: 0 0 20px 0;
            &:last-child {
                margin: 0;
            }
        }
    }
    &--login {
        margin: 0 0 0 auto;
        font-size: 1.3rem;
        text-transform: uppercase;
    }
    &--footer {
        .navigation__item {
            margin: 0 0 10px 0;
            h3 {
                font-size: 2.4rem;
            }
        }
    }
}

.user-account {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    &__info {
        display: block;
        margin-right: 15px;
        color: #fff;
        font-size: 1.6rem;
        text-decoration: none;
        &--name {
            display: block;
            flex: 0 0 100%;
            text-align: right;
            letter-spacing: .2px;
            font-weight: 600;
            line-height: 1.1;
            margin-bottom: 2px;
        }
        &--role {
            display: block;
            flex: 0 0 100%;
            text-align: right;
            text-transform: uppercase;
            letter-spacing: .2px;
            font-size: 1.1rem;
            color: $accent;
        }
    }
    &__avatar {
        display: block;
        width: 45px;
        height: 45px;
        margin: 0 10px 0 0;
        background: #353535;
        border-radius: 50%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
    }
    &__settings {
        position: relative;
        background: transparent;
        border: 0;
        cursor: pointer;
        &:focus {
            outline: none;
        }
        &__icon {
            display: block;
            width: 14px;
            height: 14px;                   
            background-image: url(/images/arrow-down-white.svg);
            background-size: 11px;
            background-repeat: no-repeat;
            background-position: center;
            font-size: 0;
            text-decoration: none;        
            margin: 0;
            padding-left: 3px;
            opacity: .3;
            transition: .3s opacity ease;
            &:hover {
                opacity: 1;
            }
        }
        .navigation {
            position: absolute;
            top: 42px;
            right: 0;
            width: 170px;
            padding: 15px;
            background: $dark;
            border-radius: 0 0 $radius $radius;
            box-shadow: $shadow;
            ul {
                text-align: left;
            }
            &__item {
                margin: 0 0 12px 0;
                &:last-child {
                    margin: 5px 0 0 0;
                    padding: 10px 0 0 0;
                    border-top: 1px solid #424242;
                }
            }
        }       
    }
}

// Main Components Style
.main {
    margin-top: 70px;
    min-height: calc(100vh - 440px);
}

.hero {
    padding: 50px 0;
    background-color: #2e2e2e;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    &__content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row wrap;
        height: auto;
        min-height: calc(100vh - 540px);
    }    
    &__text {
        text-align: center;
        color: #fff;
    }
    &__title {
        font-size: 3.6rem;
        font-weight: 400;
        line-height: 1.2;
        // text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
        margin: 0 0 20px 0;
        b {
            position: relative;
            &::after {
                position: absolute;
                content: '';
                width: 100%;
                height: 2px;
                background: $accent;
                left: 0;
                bottom: -5px;
            }
        }
    }
    &__subtitle {
        font-size: 2.2rem;
        line-height: 1.2;
        // text-shadow: 0 0 10px rgba(0, 0, 0, 0.75);
        margin: 0 0 40px 0;
    }
}

.pricing {
    &__content {
        padding: 100px 0;
    }
    .pricing-plans {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;
        &__plan {
            flex: 0 0 calc(100% / 4 - 10px);
            padding: 30px;
            box-shadow: $shadow;
            border-radius: $radius;
            &--bestseller {
                position: relative;
                border-radius: 0 0 $radius $radius;
                &::after {
                    position: absolute;
                    top: -22px;
                    left: 0;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 22px;
                    color: #fff;
                    background: $accent;
                    text-align: center;
                    font-size: 1.2rem;
                    font-weight: 600;
                    line-height: 1;
                    content: 'BESTSELLER';
                    border-radius: $radius $radius 0 0;
                }
            }
            &__title {
                font-size: 1.8rem;
                font-weight: 600;
                color: $dark;
                text-align: center;
                line-height: 1.2;
                margin: 0 0 10px 0;
            }
            &__price {
                text-align: center;
                font-size: 3.6rem;
                line-height: 1.1;
                color: $accent;
                margin: 0 0 15px 0;
                &::after {
                    display: block;
                    text-align: center;
                    font-size: 1.2rem;
                    margin: 5px 0 0 0;
                    color: gray;
                }
                &--monthly::after {
                    content: 'pay per month';                    
                }
                &--yearly::after {
                    content: 'billed annually';                    
                }
            }
            &__description {
                text-align: center;
                font-size: 1.4rem;
                color: gray;
                line-height: 1.3;
                margin: 0 0 30px 0;
            }
            &__contains {
                list-style: none;
                width: calc(100% + 60px);
                margin-left: -30px;
                li {
                    display: block;
                    width: 100%;
                    padding: 10px 30px;
                    font-size: 1.4rem;
                    line-height: 1.2;
                    background:#fcfcfc;
                    &:nth-child(odd) {
                        background: #f6f6f6;
                    }
                }
            }
        }
    }
    .pricing-plans-switcher {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-flow: row wrap;
        margin: 0 0 40px 0;
        &__label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            input {
                display: none;
            }
        }
        &__option {
            flex: 0 0 auto;
            margin: 0;
            font-size: 1.4rem;
            font-weight: 600;
            color: #717171;
            background: transparent;
            border: none;
            cursor: pointer;
            &:focus {
                outline: none;
            }
        }
        &__btn {
            position: relative;
            display: inline-flex;
            width: 62px;
            height: 30px;
            background: #f3f3f3;
            border-radius: 30px;
            border: 2px solid #dadada;
            cursor: pointer;
            margin: 0 15px;
            &::after {
                position: absolute;
                content: '';
                display: block;
                width: 18px;
                height: 18px;
                border-radius: 50%;
                background: $accent;
                top: 50%;
                left: 5px;
                transform: translate(0, -50%);
                will-change: transform;
                transition: .3s transform ease-in-out;
            }
            &--yearly::after {
                transform: translate(30px, -50%)
            }
        }
    }
}

.dashboard {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    flex-flow: row wrap;
    min-height: calc(100vh - 70px);

    &__nav {
        flex: 0 0 300px;
        color: #fff;
        background: #1c1c1c;
        transition: .3s transform ease-in-out;
        padding: 30px;
    }
    &__content {        
        flex: 0 0 calc(100% - 300px);
        padding: 30px;
    }

    &--hidden {
        .dashboard__nav {
            transform: translatex(-230px);
        }
        .dashboard__content {
            flex: 0 0 calc(100% - 30px);
        }
    }
}

.dashboard-main {
    &__content {
        display: flex;
        width: 100%;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-items: flex-start;
    }
}

.team {
    &__person {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;
        padding: 10px 0;
        border-bottom: 1px solid #f5f5f5;
        &:first-child {
            padding: 0 0 10px 0;
        }
        &:last-child {
            padding: 10px 0 0 0;
            border-bottom: 0;
        }
        &__avatar {
            display: inline-flex;
            width: 45px;
            height: 45px;
            border-radius: 50%;
            background-color: #eee;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
        }
        &__info {
            margin: 0 auto 0 15px;
            &--name {
                display: block;
                font-size: 1.6rem;
                letter-spacing: .2px;
                font-weight: 600;
                line-height: 1.1;
                margin-bottom: 2px;
            }
            &--role {
                display: block;
                text-transform: uppercase;
                letter-spacing: .2px;
                font-size: 1.1rem;
                color: $accent;
            }
        }
    }
}

.sign-form {
    padding: 50px 0;
    &__content {
        max-width: 600px;
        padding: 30px;
        box-shadow: $shadow;
    }
}

// Footer Components Style
.footer {
    padding: 50px 0;
    color: #fff;
    background: $dark;
    &__content {
        display: flex;
        min-height: 270px;
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;
    }
    &__company {
        flex: 0 0 30%;
        p {
            margin: 0 0 10px 0;
        }
    }
    &__logo {
        margin: 0 0 25px 0 !important;
        .logo {
            width: 100%;
            max-width: 170px;
        }
    }
    &__navigation {
        flex: 0 0 calc(70% / 3);
    }
    &__copyrights {
        flex: 0 0 100%;
        text-align: center;
        margin-top: 50px;
        p {
            font-size: 1.3rem;
            font-weight: 600;
            color: #636363;
        }
    }
    .social-media {
        margin: 30px 0 0 0;
    }
}
.social-media {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row wrap;
    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;        
        width: 44px;
        height: 44px;
        border-radius: 50%;
        border: 2px solid $accent;
        margin: 0 15px 0 0;
        a {
            color: #fff;
            text-decoration: none;
        }
    }
}

.newsletter {
    &__form {
        .btn {
            flex: 0 0 90px;
            margin-left: 10px;
        }
    }
}

// General Styles
.cg {
    padding: 0 5px;
    &--row {
        display: flex;
        width: calc(100% + 10px);
        justify-content: space-between;
        align-items: flex-start;
        flex-flow: row wrap;
        margin: 0 -5px;
    }       
    &-1 {
        flex: 0 0 10%;
    }
    &-2 {
        flex: 0 0 20%;
    }
    &-3 {
        flex: 0 0 30%;
    }
    &-4 {
        flex: 0 0 40%;
    }
    &-5 {
        flex: 0 0 50%;
    }
    &-6 {
        flex: 0 0 60%;
    }
    &-7 {
        flex: 0 0 70%;
    }
    &-8 {
        flex: 0 0 80%;
    }
    &-9 {
        flex: 0 0 90%;
    }
    &-10 {
        flex: 0 0 100%;
    }
}

.section-header {
    font-size: 2.6rem;
    line-height: 1.1;
    margin: 0 0 20px 0;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    &__wheel {
        display: block;
        width: 30px;
        height: 30px;
        background-image: url('https://loading.io/spinners/microsoft/lg.rotating-balls-spinner.gif');
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center;
    }
}

.form {
    &__label {
        text-align: left;
        display: block;
        margin: 0 0 20px 0;
        &:last-child {
            margin: 0;
        }
        span {
            display: block;
            width: 100%;
            font-size: 1.3rem;
            margin: 0 0 3px 0;
        }
        &__error {
            font-size: 1.2rem;
            margin: 5px 0 0 0;
            color: #ea4040;
            &:empty {
                display: none;
            }
        }
        &--error {
            .form {
                &__input {
                    border-color: #ea4040 !important;
                }
            }
        }
        &--dual {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row nowrap;
        }
        &--two {
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row wrap;
            div {
                flex: 0 0 calc(50% - 10px);
            }
        }
    }
    &__input {
        display: block;
        width: 100%;
        height: 42px;
        padding: 0 15px;
        font-family: 'Roboto', sans-serif;
        font-size: 1.5rem;
        color: #fff;
        border: 2px solid #444;
        border-radius: $radius;
        background: transparent;
        &:focus {
            outline: none;
            border-color: #767676;
        }
    }

    &--light {
        .form__input {
            color: $dark;
            border-color: #f1f1f1;
            &:focus {
                border-color: #d6d6d6;
            }
        }
    }
}

.box {
    display: block;
    width: 100%;
    height: auto;
    background: #fff;
    border-radius: $radius;
    box-shadow: $shadow;
    margin: 0 0 10px 0;
    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row nowrap;
        padding: 12px 15px;
        border-bottom: 1px solid #f5f5f5;
    }
    &__title {
        font-size: 1.6rem;
    }
    &__toggle {
        display: inline-flex;
        width: 14px;
        height: 14px;
        background-color: transparent;
        background-image: url('/images/arrow-down-dark.svg');
        background-size: 14px;
        background-position: center;
        background-repeat: no-repeat;
        border: 0;
        opacity: .2;
        transform: rotate(180deg);
        transition: .3s all ease;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
        &:focus {
            outline: none;
        }
    }
    &__content {
        height: auto;
        padding: 15px;
        overflow-y: auto;
        p {
            font-size: 1.5rem;
            line-height: 1.3;
            color: $dark;
            margin: 0 0 15px 0;
            &:last-child {
                margin: 0;
            }
        }
    }

    &--hidden {
        .box {
            &__header {
                border-bottom: 0;
            }
            &__toggle {
                transform: rotate(0deg);
            }
            &__content {
                display: none;
            }
        }
    }
}

.btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;    
    flex-flow: row nowrap;
    width: auto;
    height: 42px;
    color: #fff;
    border: 0;
    border-radius: $radius;
    background: $accent;
    padding: 0 50px;
    font-size: 1.4rem;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
    letter-spacing: .5px;
    box-shadow: $shadow;
    cursor: pointer;
    transition: .3s background ease-in-out;
    overflow: hidden;
    &:hover {
        color: #fff !important;
        background: $accentHover;
    }
    &--border {
        color: $accent;
        border: 2px solid $accent;
        background: transparent;
        &:hover {
            color: #fff;
            background: $accent;
        }
    }
    &--large {
        font-size: 1.7rem;
        height: 62px;
    }
    &--compact {
        padding: 0 15px;
        width: 100%;        
    }
}