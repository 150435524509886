@import url(https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box; }

html {
  font-size: 10px; }

body {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem; }

.app {
  min-height: calc(100vh - 70px); }

.grid {
  width: calc(100% - 100px);
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto; }
  .grid--full {
    width: 100%;
    max-width: 100%;
    padding-left: 30px;
    padding-right: 30px; }

.page-error {
  display: flex;
  width: 100%;
  min-height: calc(100vh - 440px);
  justify-content: center;
  align-items: center;
  padding: 150px 0; }
  .page-error__content {
    text-align: center; }
    .page-error__content h1 {
      font-size: 4.8rem;
      color: #212121;
      line-height: 1.1;
      margin: 0 0 15px 0; }
    .page-error__content p {
      font-size: 1.9rem;
      color: #212121;
      line-height: 1.3;
      margin: 0 0 5px 0; }
      .page-error__content p:last-child {
        margin: 0; }
    .page-error__content .btn {
      margin-top: 40px; }

.soon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  width: 100%;
  height: 100vh;
  color: #fff;
  background: #212121; }
  .soon__content {
    text-align: center; }
    .soon__content .logo {
      margin: 0 0 30px 0;
      width: 100%;
      max-width: 170px; }
    .soon__content h1 {
      font-size: 3.6rem;
      line-height: 1.1;
      margin: 0 0 10px 0; }
    .soon__content p {
      line-height: 1.1;
      font-size: 1.9rem;
      margin: 0 0 30px 0; }

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #212121;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  z-index: 998; }
  .header__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap;
    height: 70px; }

.logo {
  display: inline-block;
  width: 100%;
  max-width: 120px; }
  .logo img {
    display: block;
    width: 100%; }

.navigation {
  margin: 0 auto 0 30px; }
  .navigation ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap; }
  .navigation__item {
    margin: 0 20px 0 0; }
    .navigation__item:last-child {
      margin: 0; }
    .navigation__item a {
      color: #fff;
      text-decoration: none; }
      .navigation__item a:hover {
        color: #faa650; }
    .navigation__item--drop {
      position: relative; }
      .navigation__item--drop .navigation__toggle {
        position: relative; }
        .navigation__item--drop .navigation__toggle::after {
          content: '';
          display: inline-block;
          width: 8px;
          height: 6px;
          background-image: url(/images/arrow-down-white.svg);
          background-size: cover;
          margin: 0 0 1px 5px;
          vertical-align: middle; }
      .navigation__item--drop .navigation__dropdown {
        display: none;
        position: absolute;
        left: -15px;
        width: 250px;
        padding: 25px 15px 15px 15px;
        background: #212121;
        border-radius: 0 0 4px 4px;
        box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); }
      .navigation__item--drop .navigation__item {
        display: block;
        width: 100%;
        margin: 0 0 15px 0; }
        .navigation__item--drop .navigation__item:last-child {
          margin: 0; }
      .navigation__item--drop:hover .navigation__dropdown {
        display: block; }
    .navigation__item--button a {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-flow: row wrap;
      height: 42px;
      padding: 0 25px;
      font-weight: 600;
      color: #fff;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      background: #faa650;
      border-radius: 4px; }
      .navigation__item--button a:hover {
        color: #fff;
        background: #fdaf60; }
  .navigation--vertical {
    margin: 0; }
    .navigation--vertical .navigation__item {
      float: none;
      display: block;
      width: 100%;
      margin: 0 0 20px 0; }
      .navigation--vertical .navigation__item:last-child {
        margin: 0; }
  .navigation--login {
    margin: 0 0 0 auto;
    font-size: 1.3rem;
    text-transform: uppercase; }
  .navigation--footer .navigation__item {
    margin: 0 0 10px 0; }
    .navigation--footer .navigation__item h3 {
      font-size: 2.4rem; }

.user-account {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap; }
  .user-account__info {
    display: block;
    margin-right: 15px;
    color: #fff;
    font-size: 1.6rem;
    text-decoration: none; }
    .user-account__info--name {
      display: block;
      flex: 0 0 100%;
      text-align: right;
      letter-spacing: .2px;
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 2px; }
    .user-account__info--role {
      display: block;
      flex: 0 0 100%;
      text-align: right;
      text-transform: uppercase;
      letter-spacing: .2px;
      font-size: 1.1rem;
      color: #faa650; }
  .user-account__avatar {
    display: block;
    width: 45px;
    height: 45px;
    margin: 0 10px 0 0;
    background: #353535;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center; }
  .user-account__settings {
    position: relative;
    background: transparent;
    border: 0;
    cursor: pointer; }
    .user-account__settings:focus {
      outline: none; }
    .user-account__settings__icon {
      display: block;
      width: 14px;
      height: 14px;
      background-image: url(/images/arrow-down-white.svg);
      background-size: 11px;
      background-repeat: no-repeat;
      background-position: center;
      font-size: 0;
      text-decoration: none;
      margin: 0;
      padding-left: 3px;
      opacity: .3;
      -webkit-transition: .3s opacity ease;
      transition: .3s opacity ease; }
      .user-account__settings__icon:hover {
        opacity: 1; }
    .user-account__settings .navigation {
      position: absolute;
      top: 42px;
      right: 0;
      width: 170px;
      padding: 15px;
      background: #212121;
      border-radius: 0 0 4px 4px;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); }
      .user-account__settings .navigation ul {
        text-align: left; }
      .user-account__settings .navigation__item {
        margin: 0 0 12px 0; }
        .user-account__settings .navigation__item:last-child {
          margin: 5px 0 0 0;
          padding: 10px 0 0 0;
          border-top: 1px solid #424242; }

.main {
  margin-top: 70px;
  min-height: calc(100vh - 440px); }

.hero {
  padding: 50px 0;
  background-color: #2e2e2e;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }
  .hero__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row wrap;
    height: auto;
    min-height: calc(100vh - 540px); }
  .hero__text {
    text-align: center;
    color: #fff; }
  .hero__title {
    font-size: 3.6rem;
    font-weight: 400;
    line-height: 1.2;
    margin: 0 0 20px 0; }
    .hero__title b {
      position: relative; }
      .hero__title b::after {
        position: absolute;
        content: '';
        width: 100%;
        height: 2px;
        background: #faa650;
        left: 0;
        bottom: -5px; }
  .hero__subtitle {
    font-size: 2.2rem;
    line-height: 1.2;
    margin: 0 0 40px 0; }

.pricing__content {
  padding: 100px 0; }

.pricing .pricing-plans {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-flow: row wrap; }
  .pricing .pricing-plans__plan {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% / 4 - 10px);
    padding: 30px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px; }
    .pricing .pricing-plans__plan--bestseller {
      position: relative;
      border-radius: 0 0 4px 4px; }
      .pricing .pricing-plans__plan--bestseller::after {
        position: absolute;
        top: -22px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 22px;
        color: #fff;
        background: #faa650;
        text-align: center;
        font-size: 1.2rem;
        font-weight: 600;
        line-height: 1;
        content: 'BESTSELLER';
        border-radius: 4px 4px 0 0; }
    .pricing .pricing-plans__plan__title {
      font-size: 1.8rem;
      font-weight: 600;
      color: #212121;
      text-align: center;
      line-height: 1.2;
      margin: 0 0 10px 0; }
    .pricing .pricing-plans__plan__price {
      text-align: center;
      font-size: 3.6rem;
      line-height: 1.1;
      color: #faa650;
      margin: 0 0 15px 0; }
      .pricing .pricing-plans__plan__price::after {
        display: block;
        text-align: center;
        font-size: 1.2rem;
        margin: 5px 0 0 0;
        color: gray; }
      .pricing .pricing-plans__plan__price--monthly::after {
        content: 'pay per month'; }
      .pricing .pricing-plans__plan__price--yearly::after {
        content: 'billed annually'; }
    .pricing .pricing-plans__plan__description {
      text-align: center;
      font-size: 1.4rem;
      color: gray;
      line-height: 1.3;
      margin: 0 0 30px 0; }
    .pricing .pricing-plans__plan__contains {
      list-style: none;
      width: calc(100% + 60px);
      margin-left: -30px; }
      .pricing .pricing-plans__plan__contains li {
        display: block;
        width: 100%;
        padding: 10px 30px;
        font-size: 1.4rem;
        line-height: 1.2;
        background: #fcfcfc; }
        .pricing .pricing-plans__plan__contains li:nth-child(odd) {
          background: #f6f6f6; }

.pricing .pricing-plans-switcher {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  margin: 0 0 40px 0; }
  .pricing .pricing-plans-switcher__label {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .pricing .pricing-plans-switcher__label input {
      display: none; }
  .pricing .pricing-plans-switcher__option {
    flex: 0 0 auto;
    margin: 0;
    font-size: 1.4rem;
    font-weight: 600;
    color: #717171;
    background: transparent;
    border: none;
    cursor: pointer; }
    .pricing .pricing-plans-switcher__option:focus {
      outline: none; }
  .pricing .pricing-plans-switcher__btn {
    position: relative;
    display: inline-flex;
    width: 62px;
    height: 30px;
    background: #f3f3f3;
    border-radius: 30px;
    border: 2px solid #dadada;
    cursor: pointer;
    margin: 0 15px; }
    .pricing .pricing-plans-switcher__btn::after {
      position: absolute;
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #faa650;
      top: 50%;
      left: 5px;
      -webkit-transform: translate(0, -50%);
              transform: translate(0, -50%);
      will-change: transform;
      -webkit-transition: .3s transform ease-in-out;
      transition: .3s transform ease-in-out; }
    .pricing .pricing-plans-switcher__btn--yearly::after {
      -webkit-transform: translate(30px, -50%);
              transform: translate(30px, -50%); }

.dashboard {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-flow: row wrap;
  min-height: calc(100vh - 70px); }
  .dashboard__nav {
    flex: 0 0 300px;
    color: #fff;
    background: #1c1c1c;
    -webkit-transition: .3s transform ease-in-out;
    transition: .3s transform ease-in-out;
    padding: 30px; }
  .dashboard__content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 300px);
    padding: 30px; }
  .dashboard--hidden .dashboard__nav {
    -webkit-transform: translatex(-230px);
            transform: translatex(-230px); }
  .dashboard--hidden .dashboard__content {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(100% - 30px); }

.dashboard-main__content {
  display: flex;
  width: 100%;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start; }

.team__person {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-flow: row wrap;
  padding: 10px 0;
  border-bottom: 1px solid #f5f5f5; }
  .team__person:first-child {
    padding: 0 0 10px 0; }
  .team__person:last-child {
    padding: 10px 0 0 0;
    border-bottom: 0; }
  .team__person__avatar {
    display: inline-flex;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-color: #eee;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
  .team__person__info {
    margin: 0 auto 0 15px; }
    .team__person__info--name {
      display: block;
      font-size: 1.6rem;
      letter-spacing: .2px;
      font-weight: 600;
      line-height: 1.1;
      margin-bottom: 2px; }
    .team__person__info--role {
      display: block;
      text-transform: uppercase;
      letter-spacing: .2px;
      font-size: 1.1rem;
      color: #faa650; }

.sign-form {
  padding: 50px 0; }
  .sign-form__content {
    max-width: 600px;
    padding: 30px;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1); }

.footer {
  padding: 50px 0;
  color: #fff;
  background: #212121; }
  .footer__content {
    display: flex;
    min-height: 270px;
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap; }
  .footer__company {
    flex: 0 0 30%; }
    .footer__company p {
      margin: 0 0 10px 0; }
  .footer__logo {
    margin: 0 0 25px 0 !important; }
    .footer__logo .logo {
      width: 100%;
      max-width: 170px; }
  .footer__navigation {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(70% / 3); }
  .footer__copyrights {
    flex: 0 0 100%;
    text-align: center;
    margin-top: 50px; }
    .footer__copyrights p {
      font-size: 1.3rem;
      font-weight: 600;
      color: #636363; }
  .footer .social-media {
    margin: 30px 0 0 0; }

.social-media {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-flow: row wrap; }
  .social-media__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: 2px solid #faa650;
    margin: 0 15px 0 0; }
    .social-media__item a {
      color: #fff;
      text-decoration: none; }

.newsletter__form .btn {
  flex: 0 0 90px;
  margin-left: 10px; }

.cg {
  padding: 0 5px; }
  .cg--row {
    display: flex;
    width: calc(100% + 10px);
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    margin: 0 -5px; }
  .cg-1 {
    flex: 0 0 10%; }
  .cg-2 {
    flex: 0 0 20%; }
  .cg-3 {
    flex: 0 0 30%; }
  .cg-4 {
    flex: 0 0 40%; }
  .cg-5 {
    flex: 0 0 50%; }
  .cg-6 {
    flex: 0 0 60%; }
  .cg-7 {
    flex: 0 0 70%; }
  .cg-8 {
    flex: 0 0 80%; }
  .cg-9 {
    flex: 0 0 90%; }
  .cg-10 {
    flex: 0 0 100%; }

.section-header {
  font-size: 2.6rem;
  line-height: 1.1;
  margin: 0 0 20px 0; }

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px; }
  .loading__wheel {
    display: block;
    width: 30px;
    height: 30px;
    background-image: url("https://loading.io/spinners/microsoft/lg.rotating-balls-spinner.gif");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center; }

.form__label {
  text-align: left;
  display: block;
  margin: 0 0 20px 0; }
  .form__label:last-child {
    margin: 0; }
  .form__label span {
    display: block;
    width: 100%;
    font-size: 1.3rem;
    margin: 0 0 3px 0; }
  .form__label__error {
    font-size: 1.2rem;
    margin: 5px 0 0 0;
    color: #ea4040; }
    .form__label__error:empty {
      display: none; }
  .form__label--error .form__input {
    border-color: #ea4040 !important; }
  .form__label--dual {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap; }
  .form__label--two {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row wrap; }
    .form__label--two div {
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: calc(50% - 10px); }

.form__input {
  display: block;
  width: 100%;
  height: 42px;
  padding: 0 15px;
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  color: #fff;
  border: 2px solid #444;
  border-radius: 4px;
  background: transparent; }
  .form__input:focus {
    outline: none;
    border-color: #767676; }

.form--light .form__input {
  color: #212121;
  border-color: #f1f1f1; }
  .form--light .form__input:focus {
    border-color: #d6d6d6; }

.box {
  display: block;
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  margin: 0 0 10px 0; }
  .box__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: row nowrap;
    padding: 12px 15px;
    border-bottom: 1px solid #f5f5f5; }
  .box__title {
    font-size: 1.6rem; }
  .box__toggle {
    display: inline-flex;
    width: 14px;
    height: 14px;
    background-color: transparent;
    background-image: url("/images/arrow-down-dark.svg");
    background-size: 14px;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
    opacity: .2;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
    -webkit-transition: .3s all ease;
    transition: .3s all ease;
    cursor: pointer; }
    .box__toggle:hover {
      opacity: 1; }
    .box__toggle:focus {
      outline: none; }
  .box__content {
    height: auto;
    padding: 15px;
    overflow-y: auto; }
    .box__content p {
      font-size: 1.5rem;
      line-height: 1.3;
      color: #212121;
      margin: 0 0 15px 0; }
      .box__content p:last-child {
        margin: 0; }
  .box--hidden .box__header {
    border-bottom: 0; }
  .box--hidden .box__toggle {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  .box--hidden .box__content {
    display: none; }

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  width: auto;
  height: 42px;
  color: #fff;
  border: 0;
  border-radius: 4px;
  background: #faa650;
  padding: 0 50px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  letter-spacing: .5px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  -webkit-transition: .3s background ease-in-out;
  transition: .3s background ease-in-out;
  overflow: hidden; }
  .btn:hover {
    color: #fff !important;
    background: #fdaf60; }
  .btn--border {
    color: #faa650;
    border: 2px solid #faa650;
    background: transparent; }
    .btn--border:hover {
      color: #fff;
      background: #faa650; }
  .btn--large {
    font-size: 1.7rem;
    height: 62px; }
  .btn--compact {
    padding: 0 15px;
    width: 100%; }

